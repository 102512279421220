import { API } from "../lib/Request";
import AppConfig from "../config";

const accountUrl = AppConfig.ACCOUNT_URL;

export const GetGtpById = async (id) => {
  return new Promise((resolve, reject) => {
    API({
      url: `${accountUrl}/admin/go-to-points/${id}`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
