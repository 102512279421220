// Deps
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import Modal from "react-awesome-modal";

// Constants
import { Colors } from "../../../../themes";

// Images
import Menu from "../../../../assets/images/menu-muted.png";
import Search from "../../../../assets/images/search-muted.png";

const FilterGTP = ({ onSearch, isMobile }) => {
  const inputRef = useRef(null);

  const { t } = useTranslation();

  const [search, setSearchValue] = useState(""),
    [postalCode, setPostalCode] = useState(null),
    [region, setRegion] = useState(null),
    [showFilterModal, setShowFilterModal] = useState(false);

  const getSearch = () => {
    onSearch(search, postalCode, region);
  };

  useEffect(() => {
    const debouncedValue = setTimeout(() => {
      getSearch();
    }, 1000);
    return () => clearTimeout(debouncedValue);
  }, [search]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          flexWrap: "wrap",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: Colors.color32,
            borderRadius: "10px",
            boxShadow:
              "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
            maxWidth: isMobile ? "100%" : "400px",
            minWidth: "auto",
            flex: 1,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}
            onClick={() => inputRef.current?.focus?.()}
          >
            <img
              src={Search}
              style={{
                height: "20px",
                aspectRatio: 1 / 1,
                objectFit: "contain",
                padding: "0 14px",
                userSelect: "none",
              }}
            />
            <input
              ref={inputRef}
              className="input"
              style={{
                flex: 1,
                padding: "12px 8px",
                background: "transparent",
                border: "none",
                width: "100%",
              }}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t("SearchByLocationOrPostalCodeText")}
            />
          </div>
        </div>

        <div
          style={{
            cursor: "pointer",
            backgroundColor: Colors.color32,
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            borderRadius: "10px",
            boxShadow:
              "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)",
            width: "50px",
            height: "50px",
          }}
          onClick={() => setShowFilterModal(true)}
        >
          <img
            src={Menu}
            style={{
              height: "20px",
              aspectRatio: 1 / 1,
              objectFit: "contain",
            }}
          />
        </div>
      </div>

      <Modal
        width={isMobile ? "85%" : "508px"}
        visible={showFilterModal}
        onClickAway={() => setShowFilterModal(false)}
      >
        <div
          style={{
            gap: "22px",
            display: "flex",
            padding: "35px 40px",
            flexDirection: "column",
          }}
        >
          <h1
            style={{
              fontSize: 24,
              color: Colors.color19,
              fontWeight: 800,
              margin: 0,
            }}
          >
            Filter
          </h1>

          <div
            style={{
              gap: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3
              style={{
                margin: 0,
                fontSize: "16px",
                fontWeight: "700",
                color: Colors.color2,
              }}
            >
              Postal Code
            </h3>

            <input
              className="input2"
              type="number"
              onChange={(e) => setPostalCode(e.target.value)}
              style={{
                borderWidth: 1,
                borderRadius: 4,
                padding: "8px 12px",
                borderStyle: "solid",
                borderColor: Colors.color7,
                width: "100%",
              }}
              placeholder={t("InsertPostalCodeText")}
            />
          </div>
          <div
            style={{
              background: "#F1F3F6",
              height: 1,
            }}
          />
          <div
            style={{
              gap: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <h3
              style={{
                margin: 0,
                fontSize: "16px",
                fontWeight: "700",
                color: Colors.color2,
              }}
            >
              Region
            </h3>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "10px",
              }}
            >
              {[
                {
                  label: "North",
                  value: "NORTH",
                },
                {
                  label: "East",
                  value: "EAST",
                },
                {
                  label: "West",
                  value: "WEST",
                },
                {
                  label: "Central",
                  value: "CENTRAL",
                },
              ].map(({ label, value }) => {
                const active = value === region;
                return (
                  <button
                    key={`button-${value}`}
                    style={{
                      flex: 1,
                      minWidth: "70px",
                      padding: "4px 0",
                      fontSize: "12px",
                      borderWidth: 1.5,
                      fontWeight: "700",
                      borderStyle: "solid",
                      borderRadius: "15px",
                      color: active ? Colors.color3 : Colors.color11,
                      borderColor: Colors.color11,
                      background: active ? Colors.color8 : Colors.color3,
                    }}
                    onClick={() =>
                      active ? setRegion(null) : setRegion(value)
                    }
                  >
                    {label}
                  </button>
                );
              })}
            </div>
          </div>
          <div
            style={{
              background: "#F1F3F6",
              height: 1,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
            }}
          >
            <button
              onClick={() => setShowFilterModal(false)}
              style={{
                flex: 1,
                padding: "4px 0px",
                fontWeight: "bold",
                borderRadius: "15px",
                color: Colors.color11,
                background: Colors.color3,
                borderColor: Colors.color11,
                borderStyle: "solid",
                borderWidth: 1.5,
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => {
                getSearch();
                setShowFilterModal(false);
              }}
              style={{
                flex: 1,
                border: 0,
                padding: "4px 0px",
                fontWeight: "bold",
                borderRadius: "15px",
                color: Colors.color3,
                background: Colors.color8,
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FilterGTP;
