import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useWindowWidth } from "../../../lib/GetWIndowSize";
import Map from "../components/Map";
import GTPCard from "../components/GTPCard";
import FilterGTP from "../components/FilterGTP";
import ToggleLanguages from "../components/ToggleLanguages";
import { fetchGTPs } from "../helpers";
import { changeLanguage } from "../../../language/i18n";
import NoGTP from "../../../assets/images/no-gtp.png";
import Logo from "../../../assets/images/cara-logo.png";
import "maplibre-gl/dist/maplibre-gl.css";
import styles from "../components/styles";
import { Colors } from "../../../themes";
import "../../../styles/gtp.css";

const DesktopGTP = ({ isLanding }) => {
  const reactMapRef = useRef(null);
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const [currentCoordinate, setCurrentCoordinate] = useState({
    status: false,
    latitude: 1.2901003,
    longitude: 103.8508516,
    zoom: 15,
  });
  const [lang, setLang] = useState("en");
  const [gtps, setGtps] = useState([]);
  const [isMapLoaded, setMapLoaded] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [region, setRegion] = useState("");

  const shorthandFetchGTPsOption = {
    gtps,
    reactMapRef,
    isMobile: false,
    currentCoordinate,
    setGtps,
    setLoading,
  };

  const settingCurrentPosition = (latitude, longitude) => {
    setCurrentCoordinate({
      status: true,
      latitude,
      longitude,
    });
  };

  const handleFilter = () => {
    fetchGTPs(
      false,
      false,
      search,
      postalCode,
      region,
      shorthandFetchGTPsOption
    );
  };

  useEffect(() => {
    if (isMapLoaded) {
      navigator.geolocation.getCurrentPosition(
        (pos) =>
          settingCurrentPosition(pos.coords.latitude, pos.coords.longitude),
        (e) => {
          settingCurrentPosition(1.2901003, 103.8508516);
          console.error(e);
        }
      );
    }
  }, [isMapLoaded]);

  useEffect(() => {
    if (currentCoordinate.status) {
      fetchGTPs(
        currentCoordinate.latitude,
        currentCoordinate.longitude,
        null,
        null,
        null,
        shorthandFetchGTPsOption
      );
    }
  }, [currentCoordinate]);

  useEffect(() => {
    changeLanguage(lang);
  }, [lang]);

  return (
    <div style={styles.desktopContainer(windowWidth)}>
      {!isLanding && (
        <img
          onClick={() => (window.location.href = "/")}
          style={styles.caraLogo}
          src={Logo}
        />
      )}
      <div style={styles.rowContainer}>
        <div style={styles.mapContainer}>
          <Map
            gtps={gtps}
            reactMapRef={reactMapRef}
            activeMarker={activeMarker}
            currentCoordinate={currentCoordinate}
            setActiveMarker={setActiveMarker}
            setMapLoaded={setMapLoaded}
          />
        </div>
        <div style={styles.rowDivider} />
        <div style={styles.gtpsContainer}>
          <div className="search-container">
            <FilterGTP
              onSearch={(search, postalCode, region) => {
                setSearch(search);
                setPostalCode(postalCode);
                setRegion(region);
                handleFilter();
              }}
            />
            <button onClick={handleFilter}>
              <a href="#download">List Location</a>
            </button>
          </div>
          <h3 style={styles.nearestText}>{t("NearestGTPText")}</h3>
          <div style={styles.desktopScrollableContainer}>
            {gtps.length ? (
              gtps.map(({ id, name, address }, i) => {
                const active = id === activeMarker;
                return (
                  <GTPCard
                    id={id}
                    name={name}
                    active={active}
                    key={`gtp-${i}`}
                    address={address}
                    setActiveMarker={setActiveMarker}
                  />
                );
              })
            ) : !isLoading ? (
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <img
                  src={NoGTP}
                  style={{
                    height: 150,
                    objectFit: "contain",
                  }}
                />
                <div
                  style={{
                    gap: 4,
                    display: "flex",
                    padding: "20px 0",
                    flexDirection: "column",
                  }}
                >
                  <h3
                    style={{
                      margin: 0,
                      fontSize: 15,
                      fontWeight: 800,
                      color: Colors.color2,
                      textAlign: "center",
                    }}
                  >
                    {t("NoResultText")}
                  </h3>
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 500,
                      color: Colors.color2,
                      textAlign: "center",
                    }}
                  >
                    {t("TryAnotherLocationText")}
                  </p>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <div style={styles.footerContainer}>
        <div style={styles.tipContainer}>
          <p style={styles.emergencyText}>{t("IfThisIsAnEmergencyText")}</p>
          <p style={styles.helplineText}>{t("DementiaHelplineText")}</p>
          <div style={styles.contactContainer}>
            <p style={styles.contactNumberText}>6377 0700</p>
            <a href="/" style={styles.websiteText}>
              cara.sg
            </a>
          </div>
        </div>
        <ToggleLanguages setLang={setLang} lang={lang} />
      </div>
    </div>
  );
};

export default DesktopGTP;
