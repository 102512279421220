// Deps
import { useEffect, useRef, useState } from "react";

// Components
import Map from "../components/Map";
import GTPCard from "../components/GTPCard";

// Assets
import Faq from "../../../assets/images/faq.png";
import GPS from "../../../assets/images/gps.png";
import NoGTP from "../../../assets/images/no-gtp.png";
import CaraLogo from "../../../assets/images/cara-logo.png";

// Helpers
import { fetchGTPs, fitToMarkers } from "../helpers";

// Constants
import { Colors } from "../../../themes";

// Styles
import styles from "../components/styles";
import FilterGTP from "../components/FilterGTP";
import ModalInformation from "../components/ModalInformation";
import { t } from "i18next";

const MobileGTP = () => {
  const reactMapRef = useRef(null);
  const [search, setSearch] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [region, setRegion] = useState("");
  const [isMapLoaded, setMapLoaded] = useState(false),
    [currentCoordinate, setCurrentCoordinate] = useState({
      status: false,
      latitude: null,
      longitude: null,
    }),
    [gtps, setGtps] = useState([]),
    [activeMarker, setActiveMarker] = useState(null),
    [showInfo, setShowInfo] = useState(false),
    [isLoading, setLoading] = useState(true);

  const shorthandFetchGTPsOption = {
    gtps,
    setGtps,
    reactMapRef,
    isMobile: true,
    currentCoordinate,
    setLoading,
  };

  // Functions
  const settingCurrentPosition = (latitude, longitude) =>
    setCurrentCoordinate({
      status: true,
      latitude,
      longitude,
    });

  const handleFilter = () => {
    fetchGTPs(
      false,
      false,
      search,
      postalCode,
      region,
      shorthandFetchGTPsOption
    );
  };

  // Effects
  useEffect(() => {
    if (currentCoordinate.status)
      fetchGTPs(
        currentCoordinate.latitude,
        currentCoordinate.longitude,
        null,
        null,
        null,
        shorthandFetchGTPsOption
      );
  }, [currentCoordinate]);

  useEffect(() => {
    if (isMapLoaded)
      navigator.geolocation.getCurrentPosition(
        (pos) =>
          settingCurrentPosition(pos.coords.latitude, pos.coords.longitude),
        (e) => {
          settingCurrentPosition(1.2901003, 103.8508516);
          console.error(e);
        }
      );
  }, [isMapLoaded]);
  return (
    <div style={styles.mobileContainer}>
      <div>
        <nav
          style={{
            height: "65px",
            display: "flex",
            padding: "20px 25px",
            flexDirection: "row",
            justifyContent: "space-between",
            boxShadow: "1px 1px 5px 0px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img
            src={CaraLogo}
            style={{
              cursor: "pointer",
              objectFit: "contain",
              objectPosition: "left",
            }}
          />

          <img
            onClick={() => setShowInfo(true)}
            style={{
              cursor: "pointer",
              objectFit: "contain",
              objectPosition: "right",
            }}
            src={Faq}
          />
        </nav>
      </div>
      <div
        style={{
          flex: 1,
        }}
      >
        <Map
          gtps={gtps}
          reactMapRef={reactMapRef}
          activeMarker={activeMarker}
          currentCoordinate={currentCoordinate}
          setActiveMarker={setActiveMarker}
          setMapLoaded={setMapLoaded}
        />
      </div>
      <button
        style={styles.fitToMarker}
        onClick={() => fitToMarkers(gtps, shorthandFetchGTPsOption)}
      >
        <img src={GPS} style={styles.fitToMarkerImage} />
      </button>

      <div style={styles.bottomSheet}>
        <div className="search-container">
          <FilterGTP
            onSearch={(search, postalCode, region) => {
              setSearch(search);
              setPostalCode(postalCode);
              setRegion(region);
              handleFilter();
            }}
          />
          <button onClick={handleFilter}>
            <a href="#download">List Location</a>
          </button>
        </div>
        <h1
          style={{
            fontSize: 16,
            fontWeight: "bold",
            color: Colors.color11,
          }}
        >
          {t("NearestGTPText")}
          {/* Nearest location from the person’s living with dementia: */}
        </h1>
        <div style={styles.scrollableContainer}>
          {gtps.length ? (
            gtps.map(({ id, name, address }, i, arr) => {
              const active = id === activeMarker;
              return (
                <>
                  <GTPCard
                    id={id}
                    name={name}
                    active={active}
                    key={`gtp-${i}`}
                    address={address}
                    setActiveMarker={setActiveMarker}
                  />

                  {i === arr.length - 1 && (
                    <div
                      style={{
                        paddingBottom: "37px",
                      }}
                    />
                  )}
                </>
              );
            })
          ) : !isLoading ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src={NoGTP}
                style={{
                  height: 150,
                  objectFit: "contain",
                }}
              />

              <div
                style={{
                  gap: 4,
                  display: "flex",
                  padding: "20px 0",
                  flexDirection: "column",
                }}
              >
                <h3
                  style={{
                    margin: 0,
                    fontSize: 15,
                    fontWeight: 800,
                    color: Colors.color2,
                    textAlign: "center",
                  }}
                >
                  {t("NoResultText")}
                </h3>
                <p
                  style={{
                    margin: 0,
                    fontWeight: 500,
                    color: Colors.color2,
                    textAlign: "center",
                  }}
                >
                  {t("TryAnotherLocationText")}
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <ModalInformation
        isVisible={showInfo}
        onSetVisible={(state) => setShowInfo(state)}
      />
    </div>
  );
};

export default MobileGTP;
