import { Colors } from "../../../../themes";

export default {
  mobileContainer: {
    height: "100svh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
  },
  fitToMarker: {
    right: 12,
    border: 0,
    borderRadius: 15,
    bottom: 400 + 16,
    position: "absolute",
    padding: "5px 12.5px",
    background: Colors.primaryBgColor,
    boxShadow: "3px 3px 10px 0px rgba(204, 213, 225, 1)",
  },
  fitToMarkerImage: {
    width: 20,
    aspectRatio: 1 / 1,
    objectFit: "contain",
  },

  bottomSheet: {
    gap: 20,
    bottom: 0,
    height: 400,
    width: "100%",
    display: "flex",
    padding: "37px 30px 0 30px",
    position: "absolute",
    flexDirection: "column",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    background: `rgba(255,255,255,0.9)`,
  },

  scrollableContainer: {
    gap: 20,
    flex: "1",
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },

  desktopContainer: (windowWidth) => ({
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    padding: `70px ${
      windowWidth >= 992
        ? "100px"
        : windowWidth >= 768
        ? "75px"
        : windowWidth >= 576
        ? "50px"
        : "25px"
    }`,
    gap: "50px",
    overflow: "auto",
  }),

  caraLogo: {
    width: "150px",
    cursor: "pointer",
    userSelect: "none",
    objectFit: "contain",
    alignSelf: "self-start",
  },

  rowContainer: {
    flex: 1,
    gap: "50px",
    display: "flex",
    flexDirection: "row",
  },
  mapContainer: {
    flex: 1,
  },
  rowDivider: {
    borderLeftWidth: "1px",
    borderLeftColor: Colors.color20,
    borderLeftStyle: "dashed",
  },

  gtpsContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    position: "relative",
  },

  nearestText: {
    margin: 0,
    color: Colors.color11,
    fontSize: "16px",
    fontWeight: "bold",
  },

  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    padding: "80px",
  },

  cardContainer: {
    width: "100%",
    maxWidth: "95%",
    padding: "15px",
    borderRadius: "10px",
    boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
    marginTop: "20px",

    // Responsive adjustments
    "@media (max-width: 768px)": {
      padding: "10px",
      maxWidth: "90%",
      margin: "10px auto",
    },
    "@media (max-width: 576px)": {
      padding: "10px",
      maxWidth: "100%",
      margin: "10px auto",
    },
    wordBreak: "break-word",
    overflowWrap: "break-word",
  },

  titleContainer: {
    marginBottom: "10px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    margin: "0",
    paddingBottom: "5px",
    borderBottom: "2px solid #ddd",
  },
  details: {
    marginBottom: "10px",
    borderBottom: "2px solid #ddd",
  },

  footerContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: "50px",
  },
  tipContainer: {
    gap: "10px",
    display: "flex",
    flexDirection: "column",
    minWidth: "450px",
  },
  emergencyText: {
    color: Colors.color11,
    margin: 0,
  },
  helplineText: {
    margin: 0,
    color: Colors.color19,
  },

  contactContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  contactNumberText: {
    margin: 0,
    color: Colors.color11,
    fontWeight: "bold",
  },

  websiteText: {
    margin: 0,
    fontWeight: "bold",
    cursor: "pointer",
    color: Colors.color11,
  },
};
